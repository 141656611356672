import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { TimeagoIntl } from 'ngx-timeago';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

export class MyIntl extends TimeagoIntl {}

if (environment.production) {
  enableProdMode();
}

if (document.readyState === 'complete') {
  bootstrapApplication(AppComponent, appConfig);
} else {
  document.addEventListener('DOMContentLoaded', () => {
    bootstrapApplication(AppComponent, appConfig);
  });
}

import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageHelperService {
  private translateService = inject(TranslateService);

  languageEnums: any = {
    en_language_name: this.translateService.instant('English'),
    en_native_name: 'English',
    pt_language_name: this.translateService.instant('Portuguese'),
    pt_native_name: 'Português',
    es_language_name: this.translateService.instant('Spanish'),
    es_native_name: 'Español',
    fr_language_name: this.translateService.instant('French'),
    fr_native_name: 'Français',
    ru_language_name: this.translateService.instant('Russian'),
    ru_native_name: 'Русский',
    de_language_name: this.translateService.instant('German'),
    de_native_name: 'Deutsch',
    it_language_name: this.translateService.instant('Italian'),
    it_native_name: 'Italiano',
    zh_cn_language_name: this.translateService.instant('Chinese - Simplified'),
    zh_cn_native_name: '中文 (简体)',
    zh_tw_language_name: this.translateService.instant('Chinese - Traditional'),
    zh_tw_native_name: '中文 (繁體)',
    hu_language_name: this.translateService.instant('Hungarian'),
    hu_native_name: 'Magyar',
    ja_language_name: this.translateService.instant('Japanese'),
    ja_native_name: '日本語',
    ko_language_name: this.translateService.instant('Korean'),
    ko_native_name: '한국어',
    ar_language_name: this.translateService.instant('Arabic'),
    ar_native_name: 'العربية',
    hi_language_name: this.translateService.instant('Hindi'),
    hi_native_name: 'हिन्दी',
    bn_language_name: this.translateService.instant('Bengali'),
    bn_native_name: 'বাংলা',
    pa_language_name: this.translateService.instant('Punjabi'),
    pa_native_name: 'ਪੰਜਾਬੀ',
    ms_language_name: this.translateService.instant('Malay'),
    ms_native_name: 'Bahasa Melayu',
    sk_language_name: this.translateService.instant('Slovakian'),
    sk_native_name: 'Slovenský',
    pl_language_name: this.translateService.instant('Polish'),
    pl_native_name: 'Polski',
    bg_language_name: this.translateService.instant('Bulgarian'),
    bg_native_name: 'български',
    cs_language_name: this.translateService.instant('Czech'),
    cs_native_name: 'čeština',
    da_language_name: this.translateService.instant('Danish'),
    da_native_name: 'Dansk',
    nl_language_name: this.translateService.instant('Dutch'),
    nl_native_name: 'Nederlands',
    et_language_name: this.translateService.instant('Estonian'),
    et_native_name: 'Eesti',
    fi_language_name: this.translateService.instant('Finnish'),
    fi_native_name: 'Suomalainen',
    el_language_name: this.translateService.instant('Greek'),
    el_native_name: 'Ελληνική',
    id_language_name: this.translateService.instant('Indonesian'),
    id_native_name: 'Bahasa Indonesia',
    lv_language_name: this.translateService.instant('Latvian'),
    lv_native_name: 'Latviešu',
    lt_language_name: this.translateService.instant('Lithuanian'),
    lt_native_name: 'Lietuvių kalba',
    no_language_name: this.translateService.instant('Norwegian'),
    no_native_name: 'Norsk',
    ro_language_name: this.translateService.instant('Romanian'),
    ro_native_name: 'Românesc',
    sl_language_name: this.translateService.instant('Slovenian'),
    sl_native_name: 'Slovenščina',
    sv_language_name: this.translateService.instant('Swedish'),
    sv_native_name: 'Svenska',
    tr_language_name: this.translateService.instant('Turkish'),
    tr_native_name: 'Türkçe',
    uk_language_name: this.translateService.instant('Ukrainian'),
    uk_native_name: 'Українська',
    ps_language_name: this.translateService.instant('Pashto'),
    ps_native_name: 'پښتو',
    sq_language_name: this.translateService.instant('Albanian'),
    sq_native_name: 'Shqip',
    hy_language_name: this.translateService.instant('Armenian'),
    hy_native_name: 'Հայերեն',
    az_language_name: this.translateService.instant('Azerbaijani'),
    az_native_name: 'Azərbaycan Dili',
    be_language_name: this.translateService.instant('Belarusian'),
    be_native_name: 'беларуская мова',
    km_language_name: this.translateService.instant('Khmer'),
    km_native_name: 'ខ្មែរ',
    am_language_name: this.translateService.instant('Amharic'),
    am_native_name: 'አማርኛ',
    ur_language_name: this.translateService.instant('Urdu'),
    ur_native_name: 'اردو',
    ka_language_name: this.translateService.instant('Georgian'),
    ka_native_name: 'ქართული',
    fa_language_name: this.translateService.instant('Persian'),
    fa_native_name: 'فارسی',
    he_language_name: this.translateService.instant('Hebrew'),
    he_native_name: 'עברית',
    kk_language_name: this.translateService.instant('Kazakh'),
    kk_native_name: 'қазақ тілі',
    sw_language_name: this.translateService.instant('Swahili'),
    sw_native_name: 'Kiswahili',
    ky_language_name: this.translateService.instant('Kyrgyz'),
    ky_native_name: 'Кыргызча',
    lo_language_name: this.translateService.instant('Lao'),
    lo_native_name: 'ພາສາລາວ',
    mk_language_name: this.translateService.instant('Macedonian'),
    mk_native_name: 'македонски јазик',
    mn_language_name: this.translateService.instant('Mongolian'),
    mn_native_name: 'монгол',
    ne_language_name: this.translateService.instant('Nepali'),
    ne_native_name: 'नेपाली',
    rw_language_name: this.translateService.instant('Kinyarwanda'),
    rw_native_name: 'Ikinyarwanda',
    ta_language_name: this.translateService.instant('Tamil'),
    ta_native_name: 'தமிழ்',
    si_language_name: this.translateService.instant('Sinhala'),
    si_native_name: 'සිංහල',
    tg_language_name: this.translateService.instant('Tajik'),
    tg_native_name: 'тоҷикӣ',
    th_language_name: this.translateService.instant('Thai'),
    th_native_name: 'ไทย',
    tk_language_name: this.translateService.instant('Turkmen'),
    tk_native_name: 'Türkmen',
    uz_language_name: this.translateService.instant('Uzbek'),
    uz_native_name: 'Ozbek',
    vi_language_name: this.translateService.instant('Vietnamese'),
    vi_native_name: 'Tiếng Việt',
    my_language_name: this.translateService.instant('Burmese'),
    my_native_name: 'မြန်မာစာ',
    af_language_name: this.translateService.instant('Afrikaans'),
    af_native_name: 'Afrikaans',
    ti_language_name: this.translateService.instant('Tigrinya'),
    ti_native_name: 'ትግርኛ',
    dv_language_name: this.translateService.instant('Maldivian, Dhivehi'),
    dv_native_name: 'ދިވެހި',
    mt_language_name: this.translateService.instant('Maltese'),
    mt_native_name: 'Malti',
    sm_language_name: this.translateService.instant('Samoan'),
    sm_native_name: 'Samoa',
  };
}

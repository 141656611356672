import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom, inject, LOCALE_ID, provideZoneChangeDetection, provideAppInitializer } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, RouteReuseStrategy, UrlHandlingStrategy } from '@angular/router';
import { JWT_OPTIONS } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AUTH_FEATURE_KEY, authReducer } from 'libs/shared/data/stores/auth/auth.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { MessageService } from 'primeng/api';

import { AuthInterceptor } from '@offconon/admin/features/auth';
import { BASE_PATH as ADMIN_BASE_PATH } from '@offconon/admin-api';
import { BASE_PATH as CORE_BASE_PATH } from '@offconon/core-api';
import { LAYOUT_FEATURE_KEY, LayoutEffects, layoutReducer } from '@offconon/shared/features/layout';
import { COUNTRY_FEATURE_KEY } from '@offconon/shared/ui/form-elements/country-select-list';
import {
  CustomRouteReuseStrategy,
  CustomUrlHandlingStrategy,
  EnvironmentService,
  GlobalErrorHandlerService,
  initLanguageFactory,
  LanguageService,
} from '@offconon/shared/utils/services';
import { SharedUtilsTranslateModule } from '@offconon/shared/utils/translate';

import { environment } from '../environments/environment';
import { MyIntl } from '../main';
import { appRoutes } from './app-routing.routes';
import { reducers } from './root-reducer';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['auth', COUNTRY_FEATURE_KEY, LAYOUT_FEATURE_KEY],
    rehydrate: true,
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideAppInitializer(() => {
        const initializerFn = (initLanguageFactory)(inject(CustomUrlHandlingStrategy));
        return initializerFn();
      }),
    { provide: UrlHandlingStrategy, useClass: CustomUrlHandlingStrategy },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    { provide: CORE_BASE_PATH, useValue: environment.coreApi },
    { provide: ADMIN_BASE_PATH, useValue: environment.adminApi },
    importProvidersFrom(
      SharedUtilsTranslateModule,

      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
        connectInZone: true,
      }),
      StoreModule.forRoot(reducers, { metaReducers }),
      StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer),
      StoreModule.forFeature(LAYOUT_FEATURE_KEY, layoutReducer),

      EffectsModule.forFeature([LayoutEffects]),
      EffectsModule.forRoot([]),
      !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
      TimeagoModule.forRoot({
        intl: { provide: TimeagoIntl, useClass: MyIntl },
        formatter: {
          provide: TimeagoFormatter,
          useClass: TimeagoCustomFormatter,
        },
      }),
    ),

    provideAppInitializer(() => {
        const initializerFn = (() => {
        const environmentService = inject(EnvironmentService);
        return () => environmentService.setEnvironment(environment);
      })();
        return initializerFn();
      }),
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languageService: LanguageService) => {
        return languageService.getLanguageAndRegisterLocaleData();
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    MessageService,

    LanguageService,
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAnimations(),
  ],
};

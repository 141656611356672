<offconon-shared-ui-search-box [parameters]="parameters">
  <div class="flex flex-wrap searchdesign" [formGroup]="form">
    <div class="flex w-full items-center justify-between px-1 mt-2 pt-2">
      <p-iconfield styleClass="w-full" class="w-full">
        <p-inputicon styleClass="pi pi-search" />
        <input
          #key
          formControlName="translations"
          type="text"
          pInputText
          class="w-full"
          placeholder="{{ 'Type something to start the search' | translate }}" />
      </p-iconfield>
      @if (this.form.value['translations']) {
        <span class="cursor-pointer p-1 mr-2 text-red-500 text-xs" (click)="resetForm()">
          <mat-icon class="text-red-300">delete</mat-icon></span
        >
      }
    </div>
    <p-divider class="w-full" />
    @if (form.value['translations'] && recent_search_parameters?.keywords) {
      <div class="w-full">
        <h2 class="font-semibold text-xs text-blue-200 mb-2 mt-0 px-1">
          {{ 'Recent searches' | translate }}
        </h2>

        <ul class="list-none m-0 p-0">
          @for (recent_keyword of recent_search_parameters?.keywords; track recent_keyword.id) {
            <li
              class="select-none p-2 text-white-alpha-80 font-light leading-6 text-sm cursor-pointer rounded-lg hover:bg-white-alpha-20 hover:text-white"
              (click)="recentkeyword(recent_keyword?.label)">
              {{ recent_keyword?.label }}
            </li>
          }
        </ul>
      </div>
    }
    <div class="w-full">
      <p-accordion class="w-full" [style]="{ width: '100%' }" [multiple]="true">
        <p-accordion-panel class="mb-2">
          <p-accordion-header>
            <span class="flex items-center gap-2 w-full text-primary">
              <i class="pi pi-align-left mr-1"></i>
              <span class="font-bold white-space-nowrap"> {{ 'Options' | translate }}</span>
            </span>
          </p-accordion-header>
          <p-accordion-content>
            <div class="p-1 ml-2">
              <div class="flex flex-col gap-3">
                <div class="flex items-center">
                  <p-radioButton
                    [ngModelOptions]="{ standalone: true }"
                    [inputId]="'all'"
                    [value]="'all'"
                    [(ngModel)]="statusType"
                    (onClick)="resetCategory()" />
                  <label for="all" class="ml-2">{{ 'All' | translate }}</label>
                </div>

                <div class="flex items-center">
                  <p-radioButton
                    [ngModelOptions]="{ standalone: true }"
                    [inputId]="'status_filtered'"
                    [value]="'status_filtered'"
                    [(ngModel)]="statusType" />

                  <label for="status_filtered" class="ml-2">{{
                    'Search in selected modules' | translate
                  }}</label>
                </div>
              </div>
            </div>

            @if (statusType === 'status_filtered') {
              <div class="pt-2 mt-2">
                <div class="grip w-full max-w-full">
                  <div class="flex flex-col">
                    <div class="field w-full">
                      <div class="inline-flex justify-center items-center cursor-pointer">
                        <p-toggleswitch formControlName="is_ads" [inputId]="'is_ads'" />
                        <label
                          for="is_ads"
                          class="ml-3 mb-1 cursor-pointer  {{
                            form.value['is_ads'] ? 'text-teal-500' : 'text-red-500'
                          }}">
                          {{ 'Ads' | translate }}</label
                        >
                      </div>

                      <div class="inline-flex justify-center items-center cursor-pointer ml-3">
                        <p-toggleswitch
                          formControlName="is_businesses"
                          [inputId]="'is_businesses'" />
                        <label
                          for="is_businesses"
                          class="ml-3 mb-1 cursor-pointer  {{
                            form.value['is_businesses'] ? 'text-teal-500' : 'text-red-500'
                          }}">
                          {{ 'Businesses' | translate }}</label
                        >
                      </div>

                      <div class="inline-flex justify-center items-center cursor-pointer ml-3">
                        <p-toggleswitch formControlName="is_apps" [inputId]="'is_apps'" />
                        <label
                          for="is_apps"
                          class="ml-3 mb-1 cursor-pointer  {{
                            form.value['is_apps'] ? 'text-teal-500' : 'text-red-500'
                          }}">
                          {{ 'Applications' | translate }}</label
                        >
                      </div>

                      <div class="inline-flex justify-center items-center cursor-pointer ml-3">
                        <p-toggleswitch formControlName="is_members" [inputId]="'is_members'" />
                        <label
                          for="is_members"
                          class="ml-3 mb-1 cursor-pointer  {{
                            form.value['is_members'] ? 'text-teal-500' : 'text-red-500'
                          }}">
                          {{ 'Members' | translate }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </p-accordion-content>
        </p-accordion-panel></p-accordion
      >
    </div>
  </div>
</offconon-shared-ui-search-box>

import { registerLocaleData } from '@angular/common';
import localeAf from '@angular/common/locales/af';
import localeAm from '@angular/common/locales/am';
import localeAr from '@angular/common/locales/ar';
import localeAs from '@angular/common/locales/as';
import localeAz from '@angular/common/locales/az';
import localeBe from '@angular/common/locales/be';
import localeBg from '@angular/common/locales/bg';
import localeBn from '@angular/common/locales/bn';
import localeBs from '@angular/common/locales/bs';
import localeCa from '@angular/common/locales/ca';
import localeCs from '@angular/common/locales/cs';
import localeCy from '@angular/common/locales/cy';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeEt from '@angular/common/locales/et';
import localeEu from '@angular/common/locales/eu';
import localeFa from '@angular/common/locales/fa';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeGa from '@angular/common/locales/ga';
import localeGl from '@angular/common/locales/gl';
import localeGu from '@angular/common/locales/gu';
import localeHa from '@angular/common/locales/ha';
import localeHe from '@angular/common/locales/he';
import localeHi from '@angular/common/locales/hi';
import localeHr from '@angular/common/locales/hr';
import localeHu from '@angular/common/locales/hu';
import localeHy from '@angular/common/locales/hy';
import localeId from '@angular/common/locales/id';
import localeIg from '@angular/common/locales/ig';
import localeIs from '@angular/common/locales/is';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKa from '@angular/common/locales/ka';
import localeKk from '@angular/common/locales/kk';
import localeKm from '@angular/common/locales/km';
import localeKn from '@angular/common/locales/kn';
import localeKo from '@angular/common/locales/ko';
import localeKy from '@angular/common/locales/ky';
import localeLo from '@angular/common/locales/lo';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localeMk from '@angular/common/locales/mk';
import localeMl from '@angular/common/locales/ml';
import localeMn from '@angular/common/locales/mn';
import localeMr from '@angular/common/locales/mr';
import localeMs from '@angular/common/locales/ms';
import localeMt from '@angular/common/locales/mt';
import localeMy from '@angular/common/locales/my';
import localeNb from '@angular/common/locales/nb';
import localeNe from '@angular/common/locales/ne';
import localeNl from '@angular/common/locales/nl';
import localeNn from '@angular/common/locales/nn';
import localeOr from '@angular/common/locales/or';
import localePa from '@angular/common/locales/pa';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeSi from '@angular/common/locales/si';
import localeSk from '@angular/common/locales/sk';
import localeSl from '@angular/common/locales/sl';
import localeSq from '@angular/common/locales/sq';
import localeSr from '@angular/common/locales/sr';
import localeSv from '@angular/common/locales/sv';
import localeSw from '@angular/common/locales/sw';
import localeTa from '@angular/common/locales/ta';
import localeTe from '@angular/common/locales/te';
import localeTh from '@angular/common/locales/th';
import localeTr from '@angular/common/locales/tr';
import localeUk from '@angular/common/locales/uk';
import localeUr from '@angular/common/locales/ur';
import localeUz from '@angular/common/locales/uz';
import localeVi from '@angular/common/locales/vi';
import localeYo from '@angular/common/locales/yo';
import localeZh from '@angular/common/locales/zh';
import localeZu from '@angular/common/locales/zu';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class LoadLocaleDataService {
  loadData() {
    registerLocaleData(localeAf, 'af');
    registerLocaleData(localeAm, 'am');
    registerLocaleData(localeAr, 'ar');
    registerLocaleData(localeAs, 'as');
    registerLocaleData(localeAz, 'az');
    registerLocaleData(localeBe, 'be');
    registerLocaleData(localeBg, 'bg');
    registerLocaleData(localeBn, 'bn');
    registerLocaleData(localeBs, 'bs');
    registerLocaleData(localeCa, 'ca');
    registerLocaleData(localeCs, 'cs');
    registerLocaleData(localeCy, 'cy');
    registerLocaleData(localeDa, 'da');
    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeEl, 'el');
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localeEt, 'et');
    registerLocaleData(localeEu, 'eu');
    registerLocaleData(localeFa, 'fa');
    registerLocaleData(localeFi, 'fi');
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeGa, 'ga');
    registerLocaleData(localeGl, 'gl');
    registerLocaleData(localeGu, 'gu');
    registerLocaleData(localeHa, 'ha');
    registerLocaleData(localeHe, 'he');
    registerLocaleData(localeHi, 'hi');
    registerLocaleData(localeHr, 'hr');
    registerLocaleData(localeHu, 'hu');
    registerLocaleData(localeHy, 'hy');
    registerLocaleData(localeId, 'id');
    registerLocaleData(localeIg, 'ig');
    registerLocaleData(localeIs, 'is');
    registerLocaleData(localeIt, 'it');
    registerLocaleData(localeJa, 'ja');
    registerLocaleData(localeKa, 'ka');
    registerLocaleData(localeKk, 'kk');
    registerLocaleData(localeKm, 'km');
    registerLocaleData(localeKn, 'kn');
    registerLocaleData(localeKo, 'ko');
    registerLocaleData(localeKy, 'ky');
    registerLocaleData(localeLo, 'lo');
    registerLocaleData(localeLt, 'lt');
    registerLocaleData(localeLv, 'lv');
    registerLocaleData(localeMk, 'mk');
    registerLocaleData(localeMl, 'ml');
    registerLocaleData(localeMn, 'mn');
    registerLocaleData(localeMr, 'mr');
    registerLocaleData(localeMs, 'ms');
    registerLocaleData(localeMt, 'mt');
    registerLocaleData(localeMy, 'my');
    registerLocaleData(localeNb, 'nb');
    registerLocaleData(localeNe, 'ne');
    registerLocaleData(localeNl, 'nl');
    registerLocaleData(localeNn, 'nn');
    registerLocaleData(localeOr, 'or');
    registerLocaleData(localePa, 'pa');
    registerLocaleData(localePl, 'pl');
    registerLocaleData(localePt, 'pt');
    registerLocaleData(localeRo, 'ro');
    registerLocaleData(localeRu, 'ru');
    registerLocaleData(localeSi, 'si');
    registerLocaleData(localeSk, 'sk');
    registerLocaleData(localeSl, 'sl');
    registerLocaleData(localeSq, 'sq');
    registerLocaleData(localeSr, 'sr');
    registerLocaleData(localeSv, 'sv');
    registerLocaleData(localeSw, 'sw');
    registerLocaleData(localeTa, 'ta');
    registerLocaleData(localeTe, 'te');
    registerLocaleData(localeTh, 'th');
    registerLocaleData(localeTr, 'tr');
    registerLocaleData(localeUk, 'uk');
    registerLocaleData(localeUr, 'ur');
    registerLocaleData(localeUz, 'uz');
    registerLocaleData(localeVi, 'vi');
    registerLocaleData(localeYo, 'yo');
    registerLocaleData(localeZh, 'zh');
    registerLocaleData(localeZu, 'zu');
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { domainEnvironment } from 'libs/shared/utils/variables/domains';
import { developEnvironment } from 'libs/shared/utils/variables/environments/develop';
import { firebaseEnvironment } from 'libs/shared/utils/variables/firebase';
import { miscEnvironment } from 'libs/shared/utils/variables/misc';

export const environment = {
  production: false,

  ...developEnvironment,
  ...firebaseEnvironment,
  ...domainEnvironment,
  ...miscEnvironment,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

@if (type === 'globe') {
  <a
    class="cursor-pointer w-auto h-12 lg:w-20 md:flex items-center gap-2 px-4 py-2 bg-white dark:bg-gray-900 dark:text-white rounded-full mx-2"
    tooltipPosition="bottom"
    pTooltip="{{ 'Languages' | translate }}"
    (click)="langChangeMenu()">
    <i class="pi pi-globe"></i>
    @if (usedLang) {
      <span class="text-sm font-medium text-gray-400 uppercase hidden lg:block">{{
        usedLang ? usedLang.toUpperCase() : ''
      }}</span>
    }
    >
  </a>
}
@if (type === 'flag') {
  <a
    class="flex p-3 lg:px-3 lg:py-2 items-center cursor-pointer"
    tooltipPosition="bottom"
    [appendTo]="'body'"
    pTooltip="{{ 'Languages' | translate }}"
    (click)="langChangeMenu()">
    <img
      src="/assets/images/blocks/flag/flag_placeholder.png"
      style="width: 28px"
      [class]="'mr-2 flag flag-s-' + usedLang.toLowerCase()" />
    >
  </a>
}
<p-drawer
  #langChangeSidebarRef
  [appendTo]="'body'"
  [closeOnEscape]="true"
  [fullScreen]="false"
  [showCloseIcon]="false"
  [(visible)]="langChangeSidebar"
  (onHide)="hideLangChangeSidebar()">
  <div class="flex items-center justify-between mb-3">
    <div class="text-center lg:text-left lg:pr-8">
      <div class="font-bold text-xl">
        {{ 'Languages' | translate }}
      </div>
      <!--<div class="text-sm text-gray mb-3 mt-2">
        {{
          'We are available in more than 200 countries / regions. We are here for you, wherever you are.'
            | translate
        }}<sup>*</sup>
      </div>-->
    </div>
    <div class="">
      <p-button icon="pi pi-times" [link]="true" (click)="hideLangChangeSidebar()" />
    </div>
  </div>
  <div class="flex w-full items-center justify-between mt-2 pt-2">
    <p-iconfield class="w-full" styleClass="w-full">
      <p-inputicon styleClass="pi pi-search" />
      <input
        class="w-full"
        pInputText
        type="text"
        placeholder="{{ 'Search languages...' | translate }}"
        (input)="filterLanguages($event)" />
    </p-iconfield>
  </div>
  <div class="flex justify-start flex-wrap gap-3 items-center my-4">
    <div *ngFor="let item of filteredLangData; trackBy: trackByItem" class="w-36">
      <div class="flex items-center">
        <div class="mb-1 mt-1 flex items-center justify-between cursor-pointer">
          <div class="flex items-center" (click)="onLangChange(item)">
            <img
              src="/assets/images/blocks/flag/flag_placeholder.png"
              style="width: 45px"
              [class]="'mr-2 flag flag-s-' + item.code.toLowerCase()" />

            <div class="ml-1">
              <span
                class="block mb-1 text-sm white-space-nowrap"
                [ngClass]="{ 'text-primary font-bold': usedLang === item.code }">
                {{ item.languageName }}
                <p *ngIf="usedLang !== item.code" class="m-0 text-xs font-light">
                  {{ item.nativeName }}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="my-3 text-xs text-gray">
    <sup>*</sup>{{ 'We use integrated artificial intelligence for translation.' | translate }}
  </div>
</p-drawer>

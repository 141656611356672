import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import locales from 'libs/shared/features/layout/layout/src/assets/locales.json';
import { UrlService } from 'libs/shared/utils/services/src/lib/url.service';
import { variables } from 'libs/shared/utils/variables/variables';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { Drawer, DrawerModule } from 'primeng/drawer';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

import { ThemeService } from '@offconon/shared/ui/theme-switch';

import { LanguageHelperService } from '../../service/language-helper.service';

@Component({
  selector: 'offconon-language-change-only-flag',
  templateUrl: './language-change-only-flag.component.html',
  imports: [
    TooltipModule,
    DrawerModule,
    ButtonModule,
    DividerModule,
    TranslateModule,
    CommonModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
  ],
})
export class LanguageChangeOnlyFlagComponent implements OnInit {
  @ViewChild('langChangeSidebarRef') private langChangeSidebarRef: Drawer;
  langChangeSidebar = false;
  usedLang: string;
  private translate = inject(TranslateService);
  private urlService = inject(UrlService);
  private themeService = inject(ThemeService);
  private router = inject(Router);

  public languageHelperService = inject(LanguageHelperService);

  langsrc = this.translate.getLangs();
  langData: any[] = [];
  filteredLangData: any[] = [];
  rtlView: boolean = false;
  public lang = new FormControl('');

  constructor() {
    // this.translate.setDefaultLang(variables.defaultLanguage);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.filteredLangData = this.langsrc.map((item) => {
        let langName = '';
        let nativeName = '';
        switch (item) {
          case 'zh-cn':
            langName = this.languageHelperService.languageEnums['zh_cn_language_name'];
            nativeName = this.languageHelperService.languageEnums['zh_cn_native_name'];
            break;
          case 'zh-tw':
            langName = this.languageHelperService.languageEnums['zh_tw_language_name'];
            nativeName = this.languageHelperService.languageEnums['zh_tw_native_name'];
            break;
          default:
            langName = this.languageHelperService.languageEnums[item + '_language_name'];
            nativeName = this.languageHelperService.languageEnums[item + '_native_name'];
            break;
        }

        return { code: item, languageName: langName, nativeName: nativeName };
      });
    }, 1000);

    this.translate.onLangChange.subscribe((event) => {
      if (this.themeService.rtlViewLang.indexOf(event.lang) >= 0) this.rtlView = true;
      else this.rtlView = false;
    });

    if (localStorage.getItem(variables.languageCodeStorageKey)) {
      this.usedLang = localStorage.getItem(variables.languageCodeStorageKey) as string;
    } else {
      this.usedLang = this.translate.getBrowserLang() || variables.defaultLanguage;
    }
    this.translate.use(this.usedLang);
  }

  filterLanguages(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchTerm = inputElement.value.toLowerCase();

    if (!searchTerm) {
      this.filteredLangData = this.langsrc.map((item) => {
        let langName = '';
        let nativeName = '';

        switch (item) {
          case 'zh-cn':
            langName = this.languageHelperService.languageEnums['zh_cn_language_name'];
            nativeName = this.languageHelperService.languageEnums['zh_cn_native_name'];
            break;
          case 'zh-tw':
            langName = this.languageHelperService.languageEnums['zh_tw_language_name'];
            nativeName = this.languageHelperService.languageEnums['zh_tw_native_name'];
            break;
          default:
            langName = this.languageHelperService.languageEnums[item + '_language_name'];
            nativeName = this.languageHelperService.languageEnums[item + '_native_name'];
            break;
        }

        return { code: item, languageName: langName, nativeName: nativeName };
      });
    } else {
      this.filteredLangData = this.filteredLangData.filter(
        (lang) =>
          lang.languageName.toLowerCase().includes(searchTerm) ||
          lang.nativeName.toLowerCase().includes(searchTerm),
      );
    }
  }

  trackByItem(index: number, item: any): string {
    return item.code;
  }

  onLangChange(langArray: any) {
    let lang = langArray.code;
    if (lang == '') return;
    this.urlService.setLanguageStore(lang);
    this.usedLang = lang;
    this.langChangeSidebar = false;
    this.translate.use(lang);

    const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
    let currentUrlPath = this.router.url.slice(1).split(urlDelimitators);
    let url = '';
    if (locales.locales.includes(currentUrlPath[0])) {
      url = this.router.url.replace(currentUrlPath[0], lang);
    } else {
      url = `/${lang}/${currentUrlPath.join('/')}`;
    }
    window.location.href = url;
  }

  hideLangChangeSidebar() {
    this.langChangeSidebar = false;
    this.langChangeSidebarRef.hide();
  }
}

import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule, TransferState } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as fs from 'fs';
import { Observable } from 'rxjs';

// Custom loader for SSR
export class CustomLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
  ) {}

  getTranslation(lang: string): Observable<any> {
    return new Observable((observer) => {
      const assetsFolder = `${process.cwd()}/dist/apps/core/browser/assets/translate/i18n`;

      const jsonData = JSON.parse(fs.readFileSync(`${assetsFolder}/${lang}.json`, 'utf8'));

      observer.next(jsonData);
      observer.complete();
    });
  }
}
@NgModule({
  declarations: [],
  exports: [TranslateModule],
  imports: [
    TranslateModule.forRoot({
      extend: true,
      isolate: false,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/translate/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi(), withFetch())],
})
export class SharedUtilsTranslateModule {}

<a
  tooltipPosition="bottom"
  class="flex p-3 lg:px-3 lg:py-2 items-center cursor-pointer"
  [appendTo]="'body'"
  pTooltip="{{ 'Languages' | translate }}"
  (click)="langChangeSidebar = true">
  <img
    src="/assets/images/blocks/flag/flag_placeholder.png"
    style="width: 28px"
    [class]="'mr-2 flag flag-s-' + usedLang.toLowerCase()" />
</a>
<p-drawer
  #langChangeSidebarRef
  [fullScreen]="true"
  [appendTo]="'body'"
  [modal]="false"
  [closeOnEscape]="true"
  [showCloseIcon]="false"
  [(visible)]="langChangeSidebar">
  <div class="mt-0 float-right">
    <a
      tooltipPosition="bottom"
      class="p-2"
      pTooltip="{{ 'Close' | translate }}"
      (click)="hideLangChangeSidebar()">
      <i
        class="pi pi-times text-gray-400 dark:text-gray-200 hover:text-gray-300 hover:dark:text-gray-600"></i>
    </a>
  </div>
  <div class="flex flex-col lg:flex-row items-center justify-between mb-3">
    <div class="text-center lg:text-left lg:pr-8">
      <div class="text-primary font-light mt-3 mb-1 text-xl">
        {{ 'Languages' | translate }}
      </div>
      <div class="text-sm mb-3 mt-2">
        {{
          'We are available in more than 200 countries / regions. We are here for you, wherever you are.'
            | translate
        }}<sup>*</sup>
      </div>
    </div>
  </div>
  <p-divider class="w-full" />
  <div class="flex w-full items-center justify-between px-1 mt-2 pt-2">
    <p-iconfield styleClass="w-full" class="w-full">
      <p-inputicon styleClass="pi pi-search" />
      <input
        type="text"
        pInputText
        class="w-full"
        placeholder="{{ 'Search languages...' | translate }}"
        (input)="filterLanguages($event)" />
    </p-iconfield>
  </div>
  <div class="flex justify-between flex-wrap">
    <div *ngFor="let item of filteredLangData; trackBy: trackByItem">
      <div class="flex items-center justify-center m-4 md:m-5 lg:m-6">
        <div class="mb-1 mt-1 flex items-center justify-between cursor-pointer">
          <div class="flex items-center" (click)="onLangChange(item)">
            <img
              src="/assets/images/blocks/flag/flag_placeholder.png"
              style="width: 45px"
              [class]="'mr-2 flag flag-s-' + item.code.toLowerCase()" />

            <div class="ml-1">
              <span
                class="block mb-1 text-sm white-space-nowrap"
                [ngClass]="{ 'text-primary font-bold': usedLang === item.code }">
                {{ item.languageName }}
                <p *ngIf="usedLang !== item.code" class="m-0 text-xs font-light">
                  {{ item.nativeName }}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-divider class="w-full" />
  <div class="p-3 text-xs">
    <sup>*</sup>{{ 'We use integrated artificial intelligence for translation.' | translate }}
  </div>
</p-drawer>

import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from 'libs/admin/features/auth/src/lib/services/auth.service';
import { PrimeNG } from 'primeng/config';

import { LanguageChangeOnlyFlagComponent } from '@offconon/shared/features/language-change';
import { LayoutComponent } from '@offconon/shared/features/layout';
import { LayoutSearchComponent } from '@offconon/shared/features/layout/layout-search';
import {
  SharedUiThemeSwitchComponent,
  theme,
  ThemeService,
} from '@offconon/shared/ui/theme-switch';
import { PlatformService } from '@offconon/shared/utils/services';

import { SideBarService } from './services/side-bar.service';

@Component({
  selector: 'offconon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    LayoutComponent,
    LayoutSearchComponent,
    SharedUiThemeSwitchComponent,
    LanguageChangeOnlyFlagComponent,
  ],
})
export class AppComponent implements OnInit {
  private sideBarService = inject(SideBarService);

  private destroyRef = inject(DestroyRef);
  private themeService = inject(ThemeService);
  private primengConfig = inject(PrimeNG);
  private authenticationService = inject(AuthService);
  private platformService = inject(PlatformService);

  title = 'admin';
  isMobileView = false;
  sideNav: any;

  constructor() {
    this.primengConfig.theme.set(theme);

    this.themeService.mobileView
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isMobileView) => {
        this.isMobileView = isMobileView;
      });
  }

  public ngOnInit(): void {
    this.setSideNav();
    this.authenticationService.handleExpiredToken();
    this.platformService.setPlatform('admin');
  }
  setSideNav() {
    this.sideNav = this.sideBarService.getMenus();
  }
}

import { CommonModule } from '@angular/common';
import {
  afterNextRender,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { variables } from 'libs/shared/utils/variables/variables';
import { Drawer } from 'primeng/drawer';
import { TooltipModule } from 'primeng/tooltip';

import { ThemeService } from '@offconon/shared/ui/theme-switch';

@Component({
  selector: 'offconon-language-change-selector',
  imports: [TooltipModule, TranslateModule, CommonModule],
  templateUrl: './language-change-selector.component.html',
  styles: ``,
})
export class LanguageChangeSelectorComponent implements OnInit {
  @Input() type: string; //globe or flag
  @Input() langChangeSidebar: boolean = false;
  @Output() openLanguageSelectorEvent = new EventEmitter<any>();

  usedLang: string = '';
  rtlView: boolean = false;
  public lang = new FormControl('');

  @ViewChild('langChangeSidebarRef') private langChangeSidebarRef: Drawer;

  private translate = inject(TranslateService);
  private themeService = inject(ThemeService);
  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  constructor() {
    afterNextRender(() => {
      this.initializeLanguage();
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      this.rtlView = this.themeService.rtlViewLang.indexOf(event.lang) >= 0;
      this.cdr.detectChanges();
    });
  }

  langChangeMenu(): void {
    this.langChangeSidebar = true;
    this.openLanguageSelectorEvent.emit(true);
  }

  private initializeLanguage(): void {
    if (localStorage.getItem(variables.languageCodeStorageKey)) {
      this.usedLang = localStorage.getItem(variables.languageCodeStorageKey) as string;
    } else {
      this.usedLang = this.translate.getBrowserLang() || variables.defaultLanguage;
    }
    this.translate.use(this.usedLang);
  }
}
